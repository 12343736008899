import * as React from "react"

const text = {
    'de': {
        email: 'E-Mail',
        message: 'Nachricht',
        submit: 'Senden',
        sentPath: 'https://ephoria.health/de/gesendet'
    },
    'en': {
        email: 'Email',
        message: 'Message',
        submit: 'Submit',
        sentPath: 'https://ephoria.health/en/sent'
    }
}

const Form = ({lang}: {lang: 'de'|'en'}) => {

    const t = text[lang]

    return (
        <div className="form">
            <form action="https://api.web3forms.com/submit" method="POST">
                <input type="hidden" name="access_key" value="13ea2ae5-958e-4382-beb7-6654f68bd6bd" />
                <input type="hidden" name="redirect" value={t.sentPath}></input>

                <label>Name *</label>
                <input type="text" name="name" required />

                <label>{t.email} *</label>
                <input type="email" name="email" required />

                <label>{t.message} *</label>
                <textarea name="message" rows={5} required></textarea>


                <div style={{marginTop: 30}}>
                    { lang == 'de' &&
                        <div style={{float:'left'}}>Mit * gezeichnete Felder sind obligatorisch. Wir verwenden die Informationen, um dir zu antworten. Lies hierzu auch unsere <a href="/de/privacy" target="_blank">Datenschutzerklärung</a>.</div>
                    }
                    { lang == 'en' &&
                        <div>Fields marked with * are mandatory. We use the information provided here to reply to your request. Please take note of our <a href="/en/privacy" target="_blank">privacy policy</a>.</div>
                    }
                </div>
                <div style={{clear: 'both'}}></div>

                <div className="h-captcha" data-captcha="true"></div>


                <button type="submit">{t.submit}</button>

            </form>
            <script src="https://web3forms.com/client/script.js" async defer></script>
        </div>
    )
}


export default  ({lang}: {lang: 'de'|'en'}) => {
    const title = lang == 'de' ? 'Kontaktiere uns' : 'Get in Touch'

    return (
        <div className="banner contact">
            <h1>{title}</h1>

            { lang == 'en' &&
                <p>If you have a question or just want to say hello, please use the contact form below:</p>
            }
            { lang == 'de' &&
                <p>Hast du eine Frage oder möchtest du einfach mal Hallo sagen?
                    Dann benutze bitte das Kontaktformular:</p>
            }

            <Form lang={lang} />

        </div>
    )
}