import * as React from "react"
import type { HeadFC } from "gatsby"

import Headers from '../components/Headers'

import Layout from "../components/Layout"

import Contact from "../components/Contact"


const IndexPage = ({data, pageContext}: any) => {
  return (

    <Layout lang={pageContext.lang}>
        <Contact lang={pageContext.lang} />
    </Layout>
  )
}

export default IndexPage

// add headers
export const Head: HeadFC = ({ location, params, data, pageContext }: any ) => {
    let title = "Contact"
    let description = "Get in touch with us."
    if(pageContext.lang == 'de') {
        title = "Kontakt"
        description = "Kontaktiere uns."
    }

    return <Headers
      lang={pageContext.lang}
      title={title}
      description={description}
      location={location}
    />
}
